<template>
  <a-result class="bg-white" status="403" title="403" sub-title="对不起，您没有访问本页面的权限。">
    <template #extra>
      <a-button type="primary" @click="reBind">
        绑定钉钉账号
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        reBind() {
            this.$router.push({
                path: '/member/register'
            })
        }
    }
}
</script>
